import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuthContext } from 'context/AuthContext';
import { useRouteContext } from 'context/RouteContext';
// import IUserLoginForm from 'types/IUserLoginForm';

// interface IAuth {
//   // login: {
//   email: string;
//   password: string;
//   // };
// }

// Hook
// Parameter is the boolean, with default "false" value
// export const useAuth = () => {

export const useAuth = () => {
  // const [isLogedIn, setIsLogedIn] = useState<boolean>(false);
  // const [user, setUser] = useState<any>();

  console.log('useAuth');

  // const { isLogedIn, setIsLogedIn } = useAuthContext();
  const { isLogedIn, setIsLogedIn, user, setUser } = useAuthContext();
  const logout = useLogout();
  const expiresIn = Cookies.get('expiresIn');

  if (expiresIn) {
    console.log({ expiresIn });
    console.log(Date.now());
    console.log(Date.now() < +expiresIn);

    // setIsLogedIn(Date.now() < +expiresIn ? true : false);

    setIsLogedIn(true);

    setTimeout(async () => {
      await logout();
    }, +expiresIn - Date.now());

    console.log({ isLogedIn });
  }

  return { isLogedIn, setIsLogedIn, user, setUser };
};

export const useLogin = () => {
  // const submitHandler = async (formValues: IUserLoginForm) => {
  // setIsLoading(true);

  const [value, setValue] = useState<any>();

  // const loginHandler = async () => {
  //   console.log('LOGIN HANDLER!!!');
  //   console.log({ value });

  //   const request = await fetch(
  //     `${process.env.REACT_APP_API_URL}/users/login`,
  //     {
  //       method: 'POST',
  //       mode: 'cors',
  //       body: JSON.stringify({ email: value.email, password: value.password }),
  //       // credentials: 'include',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     }
  //   );
  //   const response = await request.json();

  //   console.log(response);

  //   if (response.status === 'error' && response.statusCode === 401) {
  //     // setIsLoading(false);
  //     // message.error('Incorrect email and/or password.');
  //     console.log('Incorrect email and/or password.');
  //   }

  //   if (response.status === 'success') {
  //     // setIsLoading(false);
  //     // setIsShowConfirmationForm(true);
  //   }
  //   // };

  //   // const fetchUserData = async () => {
  //   //   console.log(user);
  //   //   const data = new Data(`${process.env.REACT_APP_API_URL}/users/profile`);
  //   //   const response = await data.read();
  //   //   if (!response?.data?.user || response?.data?.user?.role !== 'admin') {
  //   //     logoutHandler();
  //   //   }
  //   //   setUser(response?.data?.user);
  //   //   // }
  //   //   console.log(user);
  //   //   console.log(location);
  //   //   // setActiveMenuItem([location.pathname]);
  //   // };
  // };

  useEffect(() => {
    console.log('useLogin');

    console.log(value);

    // value && loginHandler();
  }, [value]);

  return [value, setValue];
};

export const useLogout = () => {
  const { setPath } = useRouteContext();
  const { setIsLogedIn, setUser } = useAuthContext();

  const logout = async () => {
    console.log('Sign out');

    const request = await fetch(
      `${process.env.REACT_APP_API_URL}/users/logout`,
      {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const { status } = await request.json();

    if (status === 'success') {
      setIsLogedIn(false);
      setUser({});
      // setPath('/');

      console.log('Signed out...');
    }
  };

  return logout;
};

//   return { login, logout };
// };

import Cookies from 'js-cookie';
import AppError from 'components/AppError';

interface IData {
  url: string;
  params: {
    mode: RequestMode;
    // credentials: RequestCredentials;
    headers: { [key: string]: string };
    method?: string;
    body?: string | FormData;
  };
}

export interface IDataCreateOptions<S> {
  body: Omit<S, '_id'>;
}

type KeysOfType<T> = { [P in keyof T]: P }[keyof T];

// type KeysOfType<T> = { [P in keyof T]: P }[keyof T];

type OptionsFlags<Type> = {
  [Property in keyof Type]?: Type[Property];
};

interface IDataReadOptions {
  page?: number;
  limit?: number;
  sort?: string;
  countTotal?: boolean;
}

interface IDataReadFilter<S> {
  where?: OptionsFlags<S>;
  fields: KeysOfType<S>[];
}

export interface IDataUpdateOptions<S> {
  body: S;
}

export interface IDataDeleteOptions {
  where: any;
}

interface IUpload {
  progress: Function;
  files: File[];
  where: {
    path: string;
  };
}

interface IDataReadResponse<S> {
  status: string;
  data: {
    items: S[];
    pagination: {
      total?: number;
      pageSize: number;
      current: number;
    };
  };
}

class Data<S> implements IData {
  url: string;
  params: {
    mode: RequestMode;
    // credentials: RequestCredentials;
    headers: { [key: string]: string };
    method?: string;
    body?: string | FormData;
  };

  constructor(url: string) {
    const authToken = Cookies.get('authToken') || '';
    this.url = url;
    this.params = {
      mode: 'cors',
      // credentials: 'include', // Don't forget to specify this if you need cookies
      headers: {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
      },
    };
  }

  async upload(data: IUpload) {
    delete this.params.headers['Content-Type'];

    const { files, where, progress } = data;

    const formData = new FormData();

    Object.entries(files).map(([key, value]) => formData.append(key, value));

    // console.log(formData);

    // console.log(where);

    // this.params.method = 'POST';
    // this.params.body = formData;

    // return this._fetchData();

    const xhr = new XMLHttpRequest();
    const success = await new Promise((resolve) => {
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          console.log('upload progress:', event.loaded / event.total);
          // uploadProgress.value = event.loaded / event.total;

          progress(((event.loaded / event.total) * 100).toFixed());
        }
      });
      // xhr.addEventListener('progress', (event) => {
      //   if (event.lengthComputable) {
      //     console.log('download progress:', event.loaded / event.total);
      //     downloadProgress.value = event.loaded / event.total;
      //   }
      // });
      xhr.addEventListener('loadend', () => {
        resolve(xhr.readyState === 4 && xhr.status === 200);
      });
      xhr.open('POST', `${this.url}?${this._queryParams(where)}`, true);
      // xhr.setRequestHeader('Content-Type', 'application/octet-stream');
      xhr.withCredentials = true;
      xhr.send(formData);
    });
    // console.log('success:', success);
  }

  create(options: IDataCreateOptions<S>) {
    this.params.method = 'POST';

    // if (options) {
    //   this.params.body = JSON.stringify(options.body);
    // }

    this.params.body = JSON.stringify(options.body);

    const obj: Partial<IDataCreateOptions<S>> = options;

    delete obj.body;

    return this._fetchData(options);
  }

  read(
    options: IDataReadOptions & IDataReadFilter<S>
  ): Promise<IDataReadResponse<S>> {
    this.params.method = 'GET';

    const { fields, where, ...rest } = options;

    return this._fetchData({ fields, ...where, ...rest });
  }

  update(options: IDataUpdateOptions<S>) {
    // console.log(options.body);

    this.params.method = 'PATCH';
    // this.params.body = JSON.stringify(options.body);

    // delete options.body;

    this.params.body = JSON.stringify(options.body);

    const obj: Partial<IDataUpdateOptions<S>> = options;

    delete obj.body;

    return this._fetchData(options);
  }

  delete(options: IDataDeleteOptions) {
    this.params.method = 'DELETE';
    // return this._fetchData(ids);
    // this.params.body = JSON.stringify(options.body);

    this.params.body = JSON.stringify(options.where);

    const obj: Partial<IDataDeleteOptions> = options;

    delete obj.where;

    return this._fetchData(options);
  }

  // private _instanceOf<S>(data: any): data is S {
  //   return data;
  // }

  async _fetchData<T extends object>(options: T) {
    try {
      let url = this.url;

      // console.log(url);
      // console.log(options);

      if (
        options &&
        Object.keys(options).length !== 0 &&
        options.constructor === Object
      ) {
        // console.log(options);

        // if (this._instanceOf<IDataUpdateOptions<S>>(options)) {
        //   // ????????
        //   // TODO :: move to update method???
        //   this.params.body = JSON.stringify(options.body);

        //   delete options.body;

        //   // console.log(options['_id']);
        // }

        url = `${this.url}?${this._queryParams(options)}`;
      }

      // console.log('Request sending...');

      const response = await fetch(url, this.params);
      const data = await response.json();

      // console.log(data);

      // If have got an 500 error from server throw an exception
      if (data.status === 'error' && data.statusCode === 500) {
        throw new AppError(data);
      }

      return data;
    } catch (err: any) {
      console.error('ERROR:', err);
      throw new AppError(err.message);
    }
  }

  private _queryParams<T extends object>(options: T) {
    // params = {
    //   key: value,
    //   key1: value1,
    //   key2: value2,
    // }

    // Object.entries(options).map((value, index))

    return Object.entries(options)
      .map(([key, value]) => {
        // console.log(`${key}: ${value}`);

        return `${key}=${value}`;
      })
      .join('&');

    // return Object.keys(options) // [key, key1, key2]
    //   .reduce<string[]>(function (result, key) {
    //     // (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    //     result.push(`${key}=${options[key]}`);

    //     return result;
    //   }, [])
    //   .join('&');
  }
}

export default Data;

import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import { AuthContextProvider } from 'context/AuthContext';
import { RouteContextProvider } from 'context/RouteContext';
import Firewall from 'components/Firewall';
import firewallRules from './firewall.json';
import './index.css';
import App from './App';

const container = document.getElementById('app')!;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <Router basename={process.env.PUBLIC_URL}>
  <RouteContextProvider>
    <AuthContextProvider>
      <Firewall config={firewallRules}>
        <ConfigProvider theme={{ token: { colorPrimary: '#96aa52' } }}>
          <App />
        </ConfigProvider>
      </Firewall>
    </AuthContextProvider>
  </RouteContextProvider>
  // </Router>
);

import {
  // useEffect,
  useMemo,
  useState,
} from 'react';
import ISettings from 'components/Settings/ISettings';
import Data from 'helpers/data';

const useSettings = () => {
  const { REACT_APP_API_URL } = process.env;
  const data = new Data<ISettings>(`${REACT_APP_API_URL}/settings`);
  const [settings, setSettings] = useState<Record<string, string>>();

  const getSettings = async () => {
    const settings = await data.read({
      fields: ['key', 'value'],
      limit: 100,
    });

    const settingsList = {};

    settings?.data?.items?.map(({ key, value }) =>
      Object.assign(settingsList, {
        [key]: value[0],
      })
    );

    setSettings(settingsList);
  };

  useMemo(() => {
    getSettings();
    // console.log({ settings });
  }, []);

  return settings;
};

export default useSettings;
